<template>
    <div>
        {{ date }}
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        date() {
            if (this.type === 'processed') {
                if (this.item.events_processed_at) {
                    return format(window.convertFromUtcToLocalTime(this.item.events_processed_at.date), 'MM/DD/YY @ h:mma');
                }
                return '--';
            } else {
                return format(window.convertFromUtcToLocalTime(this.item.created_at.date), 'MM/DD/YY @ h:mma');
            }
        }
    }
};
</script>